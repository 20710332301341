// elementos da pagina do chatbots para executar as acoes

export const ELEMENTS = {

  loading: '.vs-loading .default',
  escolhaTemplate: 'input[readonly="readonly"]',
  textAreaConfiguracoesAvançadas: 'textarea[placeholder="Aqui haverá um prompt padrão de acordo com o objetivo do usuário"]',

  buttons: {
    btnChatbot: 'a[href*="chatbots"]',
    btnIniciar: 'input[name="telephone"]',
    btnProximo: 'Próximo',
    btnSalvar: 'Salvar',
    btnSalvarFluxo: 'Salvar fluxo',
    btnAdicionarConteudo: 'Adicionar Conteúdo',
    btnEditarExcluirItem: 'button[class="vs-con-dropdown parent-dropdown cursor-pointer"]',
    btnEditarDropdownMenu: 'Editar',
    btnExcluirDropdownMenu: 'Excluir',
    btnDadosSensiveis: "i[class='vs-icon notranslate icon-scale vs-button--icon feather icon-shield null']",
    btnIconeSalvar: '.icon-save'
  },

  strings: {
    strChat: 'Chatbots',
    strEditar: "Editar",
    strCriarNovo: "Criar novo",
    strFluxoPadrao: "Fluxo Padrão",
    strInteligenciaArtificial: "Inteligência Artificial",
    strContinuar: "Continuar",
    strAgendarReuniao: "Agendar Reunião",
    strVendasAtendimento: "Vendas e Atendimento",
    strConfigurarFuncionamento: "Configurar Funcionamento",
    strPadrao: "Padrão",
    strExcluidoComSucesso: 'O Chatbot foi excluído com sucesso'
  },

  inputs: {
    inputDuotalkInboxTrue: "input[value='true']",
    inputDuotalkInboxFalse: 'input[value]',
    inputDuotalkInboxCheck: "input[type='checkbox']",
    inputNome: 'input[name="name"]',
    inputMensagem: 'input[name="welcomeMessage"]',
    inputWebsite: 'input[name="website"]',
    inputEmail: 'input[name="email"]',
    inputTelefone: 'input[name="telephone"]',
  },

  tags: {
    tagButton: "button",
    tagH5: "h5",
    tagH4: 'h4',
    tagH3: 'h3',
    tagSpan: 'span'
  }

}