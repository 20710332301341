/*=========================================================================================
  File Name: moduleChatbotManagementMutations.js
  Description: Chatbot Management Mutations

==========================================================================================*/


export default {
  SET_CHATBOTS_LIST(state, list) {
    state.chatbots_list = list
  },
  SET_CURRENT_CHATBOT(state, data) {
    var parsedResponse = {}
    parsedResponse.id = data._id;
    parsedResponse.template = data.template;
    parsedResponse.name = data.name;
    parsedResponse.website = data.website;
    parsedResponse.email = data.email;
    parsedResponse.color = data.color;
    parsedResponse.welcomeMessage = data.welcomeMessage;
    parsedResponse.widgetPosition = data.widgetPosition;
    parsedResponse.whatsapp = data.whatsapp;
    parsedResponse.chatAvatar = data.chatAvatar;
    parsedResponse.formFields = data.formFields ? data.formFields : '';
    parsedResponse.formFields2 = data.formFields2 ? data.formFields2 : null;
    parsedResponse.version = data.version ? data.version : '';
    parsedResponse.isAssistant = data.isAssistant
    parsedResponse.objective = data.objective
    parsedResponse.objectiveInstruction = data.objectiveInstruction
    parsedResponse.aiCloseConversationFn = data.aiCloseConversationFn
    parsedResponse.aiForwardConversationFn = data.aiForwardConversationFn
    parsedResponse.aiOpenaiModel = data.aiOpenaiModel
    if (data.chatPageData != null) {
      parsedResponse.chatpage = {
        title: data.chatPageData.title,
        description: data.chatPageData.description,
        pageName: data.chatPageData.pageName,
        linkFacebook: data.chatPageData.linkFacebook,
        linkInstagram: data.chatPageData.linkInstagram,
        linkLinkedin: data.chatPageData.linkLinkedIn,
        linkTwitter: data.chatPageData.linkTwitter,
        linkYoutube: data.chatPageData.linkYoutube,
        linksList: data.chatPageData.linksList,
      }
    }
    state.current_chatbot = parsedResponse
  },
  PUSH_CARD_CHATPAGE(state, card) {
    state.current_chatbot.chatpage.linksList.unshift(card)
  },
  DELETE_CARD_CHATPAGE(state, id) {
    state.current_chatbot.chatpage.linksList.splice(id, 1)
  }
}
