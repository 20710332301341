import { v4 as uuidv4 } from 'uuid';

const generateUniqueId = (existingIds) => {
  let newId;
  do {
    newId = uuidv4();
  } while (existingIds.has(newId));
  existingIds.add(newId);
  return newId;
};

const deepChangeId = (flow, forceMode = false, existingIds = new Set()) => {
  let returnRoot = false;
  if (!Array.isArray(flow)) {
    flow = [flow];
    returnRoot = true;
  }
  for (let h = 0; h < flow.length; h += 1) {
    if (forceMode || flow[h].id === undefined || !flow[h].id || existingIds.has(flow[h].id)) {
      flow[h].id = generateUniqueId(existingIds);
    } else {
      existingIds.add(flow[h].id);
    }
    if (flow[h].children) {
      for (let i = 0; i < flow[h].children.length; i += 1) {
        flow[h].children[i].data = deepChangeId(flow[h].children[i].data, forceMode, existingIds);
      }
    }
  }

  return !returnRoot ? flow : flow[0];
};

export { deepChangeId };