/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State

==========================================================================================*/

export default {
  chatbots_list: [],
  current_chatbot: {
    id: 0,
    template: 0,
    name: "",
    website: "",
    email: [],
    color: "",
    welcomeMessage: "",
    widgetPosition: "",
    chatpage: {
      title: "",
      description: "",
      pageName: "",
      linkFacebook: "",
      linkInstagram: "",
      linkLinkedin: "",
      linkTwitter: "",
      linkYoutube: "",
      linksList: []
    },
    isAssistant: null,
    objective: "",
    objectiveInstruction: "",
    aiCloseConversationFn: "",
    aiForwardConversationFn: "",
    aiOpenaiModel: ""
  }
}
