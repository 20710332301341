/* global cy */
const el = require('./elements').ELEMENTS;
let nameAutomation = "test automation " + Math.floor(Math.random() * 1000);

class ChatbotDuotalk {

  acessarChatbot() {
    cy.get(el.buttons.btnChatbot).click();
    cy.get(el.tags.tagH3)
      .contains(el.strings.strChat).should('be.visible');
  }

  editarAssistenteDuoltalk(checkDuoltalkAssit) {
    cy.get(el.buttons.btnDadosSensiveis).click();
    if (checkDuoltalkAssit === "Desativa") {
      cy.get('body').then(($body) => {
        const label = $body.find('label:contains("Duotalk Assistente")');
        if (label.length > 0) {
          const nextElement = label.next('button');
          if (nextElement.length > 0) {
            cy.wrap(nextElement).then(($nextElement) => {
              const backgroundColor = $nextElement.css('background-color');
              if (backgroundColor === 'rgb(40, 199, 111)') {
                cy.wrap(nextElement).click();
                cy.log('Desativando Duotalk Assistente');
              }
            });
          }
        }
      });
    }
    else if (checkDuoltalkAssit === "Ativa") {
      cy.get('body').then(($body) => {
        const label = $body.find('label:contains("Duotalk Assistente")');
        if (label.length > 0) {
          const nextElement = label.next('button');
          if (nextElement.length > 0) {
            cy.wrap(nextElement).then(($nextElement) => {
              const backgroundColor = $nextElement.css('background-color');
              if (backgroundColor === 'rgb(40, 199, 111)') {
                cy.log('Já Ativo Duotalk Assistente');
              }else{
                cy.wrap(nextElement).click();
                cy.log('Ativando Duotalk Assistente');
              }
            });
          }
        }
      });
    }

    cy.get(el.tags.tagButton).contains(el.strings.strEditar).click({ force: true }).wait(5000);
    cy.get(el.loading).should('not.exist');
  }

  criarChatNovo() {
    cy.get(el.tags.tagH5).contains(el.strings.strCriarNovo).should('be.visible').click();
  }

  criarChatBotPadrao() {

    cy.contains(el.strings.strFluxoPadrao).should('be.visible').click();
    cy.contains(el.strings.strContinuar).should('be.visible').click();
    cy.get(el.escolhaTemplate).first().click().wait(1500);
    cy.contains(el.strings.strAgendarReuniao).should('be.visible').click();
    cy.get(el.inputs.inputNome).type(nameAutomation);
    cy.get(el.inputs.inputMensagem).type('Ola Teste');
    cy.get(el.inputs.inputWebsite).type('www.teste.com.br');
    cy.get(el.inputs.inputEmail).click().type('teste@teste.com');
    cy.get(el.inputs.inputTelefone).type('1199999999{enter}');
    cy.contains(el.buttons.btnProximo).click();
    cy.contains(el.buttons.btnSalvarFluxo).should('be.visible').click();
    cy.get(el.tags.tagH4).contains(nameAutomation).should('be.visible');
    cy.get(el.loading).should('not.exist');
  }

  criarChatBotPadraoProd() {

    cy.contains(el.strings.strFluxoPadrao).should('be.visible').click();
    cy.contains(el.strings.strContinuar).should('be.visible').click();
    cy.get(el.escolhaTemplate).first().click().wait(1500);
    cy.contains(el.strings.strVendasAtendimento).should('be.visible').click();
    cy.get(el.inputs.inputNome).type(nameAutomation);
    cy.get(el.inputs.inputMensagem).type('Ola Teste');
    cy.get(el.inputs.inputWebsite).type('www.teste.com.br');
    cy.get(el.inputs.inputEmail).click().type('teste@teste.com');
    cy.get(el.inputs.inputTelefone).type('1199999999{enter}');
    cy.contains(el.buttons.btnProximo).click();
    cy.contains(el.buttons.btnSalvarFluxo).should('be.visible').click();
    cy.get(el.tags.tagH4).contains(nameAutomation).should('be.visible');
    cy.get(el.loading).should('not.exist');
  }

  criarChatIA() {
    cy.contains(el.strings.strInteligenciaArtificial).should('be.visible').click();
    cy.contains(el.strings.strContinuar).should('be.visible').click();
    cy.get(el.inputs.inputNome).type(nameAutomation);
    cy.get(el.inputs.inputMensagem).type('Ola Teste');
    cy.get(el.inputs.inputWebsite).type('www.teste.com.br');
    cy.get(el.inputs.inputEmail).click().type('teste@teste.com');
    cy.get(el.inputs.inputTelefone).type('11999999999');
    cy.contains(el.buttons.btnProximo).click();
    cy.contains(el.strings.strConfigurarFuncionamento).wait(3500);
    //cy.get(el.escolhaTemplate).first().click();
    //cy.contains(el.strings.strPadrao).should('be.visible').click().wait(2000)
    //cy.get(el.textAreaConfiguracoesAvançadas).clear().wait(1500).type("Você é uma assistente inteligente.");
    cy.get(el.buttons.btnIconeSalvar).should('be.visible').last().click();


    //cy.get(el.tags.tagH4).contains(nameAutomation).should('be.visible');
    //cy.get(el.loading).should('not.exist');
  }

  criarChatBotInbox() {

    cy.get(el.escolhaTemplate).first().click();
    cy.contains(el.strings.strAgendarReuniao).should('be.visible').click();
    cy.get(el.inputs.inputNome).type(nameAutomation);
    cy.get(el.inputs.inputMensagem).type('Ola Teste');
    cy.get(el.inputs.inputWebsite).type('www.teste.com.br');
    cy.get(el.inputs.inputEmail).click().type('teste@teste.com');
    cy.get(el.inputs.inputTelefone).type('11999999999');
    cy.contains(el.buttons.btnProximo).click();
    cy.contains(el.buttons.btnSalvarFluxo).should('be.visible').click();
    cy.get(el.tags.tagH4).contains(nameAutomation).should('be.visible');
    cy.get(el.loading).should('not.exist');
  }

  criarChatBot(tipoBot) {
    this.criarChatNovo();

    if (tipoBot === "Padrão") {
      this.criarChatBotPadrao();
    }
    else if (tipoBot === "Padrão PROD") {
      this.criarChatBotPadraoProd();
    }
    else if (tipoBot === "IA") {
      this.criarChatIA();
    }
    else if (tipoBot === "Inbox") {
      this.criarChatBotInbox();
    }

  }

  editarChatbot(tipoBot) {
    cy.get(el.buttons.btnEditarExcluirItem).last().click();
    cy.contains(el.buttons.btnEditarDropdownMenu).click();
    cy.get(el.inputs.inputNome).type('EDITADO');
    cy.wait(1500);
    cy.contains(el.buttons.btnProximo).click();
    if (tipoBot === "Padrão PROD") {
      cy.contains(el.buttons.btnSalvarFluxo).should('be.visible').click();
      cy.wait(1500);
    }
  }

  editarChatbotPadrao() {
    cy.contains(el.buttons.btnSalvarFluxo).click();
  }

  editarChatbotIA() {
    cy.contains(el.strings.strConfigurarFuncionamento).wait(3500);
    cy.get(el.textAreaConfiguracoesAvançadas).clear().type("Você é uma assistente inteligente.EDITADO");
    cy.get(el.buttons.btnIconeSalvar).should('be.visible').last().click();
  }

  excluirChatbot() {

    cy.get('body').then($body => {
      if ($body.find(el.buttons.btnIconeSalvar).length > 0) {
        this.acessarChatbot();
      }
    })

    cy.get(el.tags.tagH4).contains(nameAutomation).should('be.visible');
    cy.get(el.buttons.btnEditarExcluirItem).last().click();
    cy.contains(el.buttons.btnExcluirDropdownMenu).click();
    cy.contains(el.buttons.btnExcluirDropdownMenu).click();
    cy.contains(el.strings.strExcluidoComSucesso);
    cy.get(el.tags.tagH4).contains(nameAutomation).should('not.exist');
  }


}

export default new ChatbotDuotalk();
