<template>
  <div>
    <div class="flex justify-center">
      <div class="w-full sm:w-1/2 fade-onboarding">
        <!-- Aparencia do chatbot-->
        <div class="fade-onboarding">
          <h1 class="text-center mb-6">{{ $t("ChatbotSettings") }}</h1>
          <div
            class="flex flex-wrap mb-3"
            v-if="!chatbotId"
            @click="isClicked = true"
          >
            <vs-select
              v-model="chatbotSelected"
              label="Escolha um template"
              width="100%"
              item-value="value"
            >
              <vs-select-item
                v-for="(item, index) in templateOptions"
                :key="index"
                :value="item.value"
                :text="item.label"
              />
            </vs-select>
          </div>
          <div class="flex flex-wrap mb-3">
            <vs-input
              label="Nome para o chat"
              v-model="name"
              name="name"
              data-vv-validate-on="blur"
              v-validate="'required'"
              :danger="errors.first('name')"
              val-icon-pack="feather"
              val-icon-danger="icon-x"
              danger-text="Este campo é obrigatório"
              class="w-full"
            />
          </div>
          <div class="flex flex-wrap mb-3">
            <vx-input-group class="w-full welcomeMessageInput">
              <vs-input
                :maxlength="welcomeMessageMaxLength"
                v-model="welcomeMessage"
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="welcomeMessage"
                class="h-12 w-full"
                :label="$t('FirstMessage')"
              />
              <template slot="append">
                <div class="append-text bg-grey mt-6">
                  <span
                    v-text="welcomeMessageMaxLength - welcomeMessage.length"
                  ></span>
                </div>
              </template>
            </vx-input-group>
          </div>
          <div class="flex flex-wrap mb-3">
            <vs-select
              v-model="selectedWidgetPosition"
              :label="$t('WidgetPosition')"
              width="100%"
              @input="setWidgetPosition"
              id="widgetposition"
              name="widgetposition"
            >
              <vs-select-item
                v-for="(item, index) in widgetPosition"
                :key="index"
                :value="item.id"
                :text="item.label"
              />
            </vs-select>
          </div>
          <div class="flex flex-wrap mb-3">
            <vs-input
              type="url"
              v-model="website"
              :label="$t('WebsiteToInstall')"
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="website"
              class="w-full"
            />
          </div>
          <div class="flex flex-wrap mb-3">
            <vs-input
              type="email"
              label="Email para recebimento de leads"
              v-model="email"
              data-vv-validate-on="blur"
              name="email"
              class="w-full"
            />
          </div>
          <div class="flex flex-wrap mb-3">
            <div
              class="vs-component vs-con-input-label vs-input w-full vs-input-primary"
            >
              <label class="vs-input--label">{{
                $t("InformTheWhatsapps")
              }}</label>
              <div class="vs-con-input telephoneInput">
                <vue-tel-input
                  v-model="whatsapp"
                  name="whatsapp"
                  class="vs-inputx vs-input--input normal"
                  @blur="blurWhatsapp"
                  @keyup.enter.native="keyupWhatsapp"
                  @validate="validatePhone"
                />
              </div>
              <span v-if="!validPhone" class="text-danger text-sm">
                {{ $t("InvalidPhone") }}
              </span>
            </div>
          </div>
          <div class="flex flex-wrap mb-3" v-if="whatsappArray.length > 0">
            <vs-chip
              closable
              color="success mr-3"
              close-icon="close"
              v-for="(currentWhats, j) in whatsappArray"
              :key="j"
              @click="removePhone(j)"
              >{{ currentWhats }}</vs-chip
            >
          </div>
          <div class="flex flex-wrap mb-3">
            <label class="vs-input--label w-full">{{
              $t("WidgetColor")
            }}</label>
            <div class="form__input mt-2">
              <v-swatches
                v-model="color"
                :swatches="swatches"
                fallback-input-type="color"
                inline
                ref="vSwatches"
              >
              </v-swatches>
            </div>
          </div>
          <div class="flex flex-wrap mb-3">
            <label class="vs-input--label w-full">{{ $t("Avatar") }}</label>
            <div class="flex flex-wrap items-center">
              <div
                class="con-vs-avatar 49px"
                style="
                  width: 49px;
                  height: 49px;
                  background: rgb(195, 195, 195);
                "
                id="avatarFileOnboarding"
              >
                <div class="con-img vs-avatar--con-img">
                  <img :src="chatAvatar" />
                </div>
              </div>
              <div>
                <feather-icon
                  @click="removeAvatar"
                  icon="TrashIcon"
                  class="p-2"
                  svgClasses="w-6 h-6 text-danger"
                />
                <avatar-cropper
                  :uploadHandler="uploadAvatar"
                  @changed="changeFile"
                  trigger="#avatarFileOnboarding"
                  mimes="image/png, image/jpeg"
                  :labels="label"
                />
              </div>
            </div>
          </div>
          <vs-button
            class="h-12 w-full mt-8"
            color="success"
            @click="submitChatbot()"
            :disabled="!validateFormConfig || disableSubmit"
            icon-pack="feather"
            icon="icon-arrow-right"
            icon-after
          >
            {{ $t("Next") }}
          </vs-button>
        </div>
        <vs-button
          class="sm:hidden preview-btn-onboarding transform rotate-90"
          @click="showChatPopup()"
          color="primary"
          type="gradient"
          icon="icon-eye"
          icon-pack="feather"
          >{{ $t("PreviewChatbot") }}
        </vs-button>
        <vs-popup
          :title="$t('ChatbotPreview')"
          :active.sync="previewChatbot"
          id="preview-chatbot"
        >
          <div class="vx-col w-full" v-if="previewChatbot">
            <div
              id="chatbot-mobile-example"
              class="top-0 vx-col w-full chatbot-example"
            ></div>
          </div>
        </vs-popup>
      </div>
      <div class="hidden sm:flex sm:w-1/2 mr-4">
        <div class="w-full mb-2 p-4 ml-6 pt-0 flex justify-center">
          <div class="chatbot-example-frame text-center">
            <h5 class="my-6 text-center" id="chatbot-ex">
              {{ $t("ChatbotPreview") }}
            </h5>
            <div id="chatbot-example"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import AvatarCropper from "vue-avatar-cropper";
import moduleChatbotManagement from "@/store/chatbot-management/moduleChatbotManagement.js";
import chatbot from "../../../../cypress/e2e/pages/chatbot";

export default {
  components: {
    VSwatches,
    AvatarCropper,
  },
  watch: {
    name: function () {
      this.postMessageToIframe();
    },
    welcomeMessage: function () {
      this.postMessageToIframe();
    },
    chatAvatar: function () {
      this.postMessageToIframe();
    },
    color() {
      this.postMessageToIframe();
    },
    whatsapp: function () {
      this.postMessageToIframe();
    },
    chatbot: function () {
      if (!this.widgetLoaded) this.loadChatWidget();
    },
  },
  computed: {
    colorName() {
      return this.color === "#002f8e"
        ? "blue"
        : this.color === "#008e46"
        ? "green"
        : this.color === "#8e0000"
        ? "red"
        : this.color === "#34495e"
        ? "dark"
        : this.color === "#6e008e"
        ? "purple"
        : this.color === "#c68700"
        ? "yellow"
        : "blue";
    },
    validateForm() {
      return this.name.length > 0;
    },
    validateFormConfig() {
      return this.chatbot !== "" && this.website !== "";
    },
    planChatType() {
      return this.$store.state.acc.current_acc.accPlan.plan.planParameters
        .chatType;
    },
    chatData() {
      return this.$store.state.chatbotManagement.current_chatbot;
    },
  },
  props: {
    templateId: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      chatbotGoal: "",
      chatbotGoalOptions: [],
      disableSubmit: false,
      chatpage: {},
      idLoading: "",
      chatbotId: "",
      label: {
        submit: this.$t("Crop"),
        cancel: this.$t("Cancel"),
      },
      template: "",
      chatbotSelected: {},
      templateOptions: [],
      name: "",
      color: "#002f8e",
      swatches: [
        "#002f8e",
        "#008e46",
        "#8e0000",
        "#34495e",
        "#6e008e",
        "#c68700",
      ],
      chatAvatar:
        "https://cdn-bucket-duotalk.s3.amazonaws.com/img/avatar/robo-avatar-duotalk.png",
      DefaultAvatar:
        "https://cdn-bucket-duotalk.s3.amazonaws.com/img/avatar/robo-avatar-duotalk.png",
      chatAvatarFile: "",
      welcomeMessage: "",
      welcomeMessageMaxLength: 120,
      selectedWidgetPosition: 1,
      widgetPosition: [
        { id: 1, label: "Canto inferior direito" },
        { id: 2, label: "Canto inferior esquerdo" },
      ],
      website: "",
      email: "",
      whatsapp: "",
      whatsappArray: [],
      publish: false,
      widgetLoaded: false,
      previewChatbot: false,
      tagsItems: [],
      companyIndustry: "",
      isCustom: false,
      isClicked: false,
      accept: false,
      acceptTemplate: {},
      validPhone: true,
      isAssistant: true,
    };
  },
  beforeMount() {
    if (this.templateId) this.chatbotSelected = this.templateId;
  },
  mounted() {
    this.getCompanyIndustry();
    let self = this;
    window.addEventListener(
      "message",
      function (e) {
        if (e.data.ready != null) {
          self.postMessageToIframe();
        }
      },
      false
    );
    if (this.$route.params.chatbot_id) {
      let chatbot = this.chatData;
      let obj = chatbot.formFields[0];
      if (typeof obj === "object") this.isCustom = true;
      this.chatbotId = this.$route.params.chatbot_id;
      this.name = chatbot.name;
      this.chatbot = chatbot.template;
      this.website = chatbot.website;
      this.email = chatbot.email ? chatbot.email.join(",") : "";
      if (chatbot.whatsapp) this.whatsappArray = chatbot.whatsapp;
      if (chatbot.widgetPosition)
        this.selectedWidgetPosition = chatbot.widgetPosition;
      if (chatbot.color) this.color = this.colorHex(chatbot.color);
      if (chatbot.chatAvatar) this.chatAvatar = chatbot.chatAvatar;
      if (chatbot.welcomeMessage) this.welcomeMessage = chatbot.welcomeMessage;
      this.isAssistant = chatbot.isAssistant;

      this.getTemplates();
    } else this.getTemplates();

    if (
      this.$refs.vSwatches &&
      this.$refs.vSwatches.$refs &&
      this.$refs.vSwatches.$refs.fallbackInput &&
      this.$refs.vSwatches.$refs.fallbackInput.id
    )
      this.$refs.vSwatches.$refs.fallbackInput.id = "color-picker";
  },
  methods: {
    chatbot() {
      return chatbot;
    },
    acceptChange() {
      this.templateId = this.acceptTemplate;
      this.isCustom = false;
      this.accept = true;
      this.chatbotSelected = this.acceptTemplate;
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    getCompanyIndustry() {
      let id = this.$store.state.acc.current_acc.id;
      this.$http.get("/g/acc/" + id).then((response) => {
        this.companyIndustry = response.data.data.acc.companyIndustry;
      });
    },
    submitChatbot() {
      if (
        this.chatbotSelected &&
        this.isEmptyObject(this.chatbotSelected) &&
        !this.isAssistant
      ) {
        this.$vs.dialog({
          color: "warning",
          title: "Selecione um template",
          text: "Por favor, selecione um template para criação do chatbot",
          accept: () => {},
          acceptText: "Ok",
        });
        this.chatbotSelected = this.templateOptions[0].value;
        return;
      }
      this.disableSubmit = true;
      const isUrl =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

      let api_uri = "/p/chat/chatbot-create";
      let data = {
        name: this.name,
        website: this.website ? this.website : "www.example.com",
        email: this.email != null && this.email.length > 3 ? this.email : null,
        whatsapp: this.whatsappArray,
        chatAvatar: isUrl.test(this.chatAvatar)
          ? this.chatAvatar
          : this.DefaultAvatar,
        welcomeMessage: this.welcomeMessage,
        widgetPosition: this.selectedWidgetPosition,
        color: this.colorName,
        acc: this.$store.state.acc.current_acc.id,
        formFields: this.isCustom ? this.chatData.formFields : [],
        template: this.chatbotSelected,
      };
      let method = "post";
      if (this.chatbotId) {
        api_uri = `/p/chat/chatbot-edit/${this.chatbotId}`;
        method = "put";
      }
      this.$http({
        method: method,
        url: api_uri,
        data: data,
      })
        .then((response) => {
          this.$store
            .dispatch("chatbotManagement/fetchChatbot", response.data.data._id)
            .then(() => {
              if (this.chatAvatar !== this.DefaultAvatar) {
                if (this.chatAvatarFile.type != null) {
                  let fd = new FormData();
                  fd.append("content", this.chatAvatar);
                  fd.append("mime", this.chatAvatarFile.type);
                  this.$http
                    .post("/p/chat/avatar/" + response.data.data._id, fd, {
                      headers: {
                        Authorization:
                          "Bearer " + this.$store.state.auth.accessToken,
                        "Content-Type": "multipart/form-data",
                      },
                    })
                    .then(() => {
                      this.finishEditOrCreate(response);
                    })
                    .catch(() => {
                      this.finishEditOrCreate(response);
                    });
                } else this.finishEditOrCreate(response);
              } else this.finishEditOrCreate(response);
            });
        })
        .catch((error) => {
          this.disableSubmit = false;
          if (error && error.request != null)
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            duration: 10000,
            position: "top-right",
            time: 4000,
          });
        });
    },
    finishEditOrCreate(response) {
      this.chatbotId = response.data.data._id;
      this.$route.params.chatbotType = "chatbot";
      this.fetchChatbot(this.chatbotId);
      this.$vs.notify({
        color: "success",
        title: this.$t("Success"),
        text: this.$t("ChatbotEditedSuccessfuly"),
        position: "top-right",
        time: 4000,
      });
    },
    fetchChatbot(id) {
      if (!moduleChatbotManagement.isRegistered) {
        this.$store.registerModule(
          "chatbotManagement",
          moduleChatbotManagement
        );
        moduleChatbotManagement.isRegistered = true;
      }
      this.$store.dispatch("chatbotManagement/fetchChatbot", id).then(() => {
        this.$router.push({
          name: "chatbot-flow-edit",
          params: { chatbot_id: this.chatbotId },
        });
      });
    },
    removePhone(key) {
      this.whatsappArray.splice(key, 1);
    },
    addWhatsapp() {
      if (
        this.whatsapp.length > 0 &&
        !this.whatsappArray.includes(this.whatsapp.replace(/\D/g, ""))
      ) {
        if (this.validPhone) {
          this.whatsappArray.push(this.whatsapp.replace(/\D/g, ""));
          this.whatsapp = "";
          this.validPhone = true;
        } else {
          this.validPhone = false;
        }
      }
    },
    getTemplates() {
      this.$http
        .get("/p/chat/template/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then((response) => {
          let templateOptionsFull = [];
          let push = false;
          for (let i = 0; i < response.data.data.length; i++) {
            push = false;
            if (this.chatbot === response.data.data[i]._id) push = true;
            else if (response.data.data[i].listingAvailable === true)
              push = true;

            if (push)
              templateOptionsFull.push({
                value: response.data.data[i]._id,
                label: response.data.data[i].name,
                chatType: response.data.data[i].chatType,
                botMessages: response.data.data[i].formFields,
              });

            if (this.chatbot === response.data.data[i]._id) {
              this.chatbotSelected = response.data.data[i]._id;
            }
          }

          if (this.templateOptions.length < 1) {
            this.templateOptions = templateOptionsFull;
            // Removing autoForce template from the start
            let autoForceTempIndex = this.templateOptions.findIndex((obj) =>
              obj.label.includes("AutoForce")
            );
            if (autoForceTempIndex !== -1) {
              let [obj] = this.templateOptions.splice(autoForceTempIndex, 1);
              this.templateOptions.push(obj);
            }
          }
          if (this.chatbotSelected.length < 1) {
            this.chatbot = this.templateOptions[0].value;
          }

          this.loadChatWidget();
        })
        .catch(() => {
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: this.$t("UnexpectedErrorLoadX", [
              this.$t("ChatbotExample").toLowerCase(),
            ]),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            duration: 10000,
            position: "top-right",
            time: 4000,
          });
        });
    },
    removeAvatar() {
      this.chatAvatar = this.DefaultAvatar;
    },
    uploadAvatar: function (photo) {
      var self = this;
      if (photo) {
        var url = photo.getCroppedCanvas().toDataURL();
        this.resizeBase64Img(url, function (url) {
          fetch(url).then((resBlob) => {
            resBlob.blob().then((blob) => {
              self.chatAvatarFile = blob;
              self.chatAvatar = url;
            });
          });
        });
      }
    },
    changeFile(file) {
      if (file)
        document.querySelector(".avatar-cropper").style.display = "flex";
    },
    setWidgetPosition(v) {
      this.selectedWidgetPosition = v;
    },
    loadChatWidget() {
      this.widgetLoaded = true;
      const self = this;
      let obj = document.getElementById("chatbot-example");
      if (obj != null) obj.innerHTML = "";
      window.__cb = window.__be || {};
      window.__cb.bind = "chatbot-example";
      window.__cb.chatOpen = true;
      window.__cb.closeButton = false;

      let template = "";
      if (this.$route.params.chatbotType === "chatbot")
        template = self.chatData.template > 0 ? self.chatData : self.chatbot;
      else template = this.templateOptions[0].value;

      window.__cb.template = template;
      (function () {
        let be = document.createElement("script");
        be.type = "text/javascript";
        be.async = true;
        be.src = process.env.VUE_APP_WIDGET_PLUGIN_URL;
        const s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(be, s);
      })();
      setTimeout(function () {
        self.postMessageToIframe();
      }, 5000);
      if (this.previewChatbot === true)
        setTimeout(function () {
          self.setChatbotMobileExample();
        }, 2000);
    },
    postMessageToIframe() {
      const iframe = document.getElementById(
        "cb-duotalk-chat-framechatbot-example"
      );
      if (iframe != null) {
        let message = {};
        if (this.name != null) message.title = this.name;
        if (this.welcomeMessage != null)
          message.welcomeMessage = this.welcomeMessage;
        if (this.chatAvatar != null) message.titleImageUrl = this.chatAvatar;
        if (this.color != null) message.color = this.colorName;
        if (this.whatsappArray.length > 0) message.whatsapp = true;
        iframe.contentWindow.postMessage(message, "*");

        if (this.templateId !== "" && this.isEmptyObject(this.chatbotSelected))
          this.chatbotSelected = this.templateOptions.find(
            (e) => e.value === this.templateId
          ).value;
        if (this.isAssistant && this.isEmptyObject(this.chatbotSelected))
          this.chatbotSelected = this.templateOptions[0].value;
      }
    },
    showChatPopup() {
      this.previewChatbot = true;
      this.loadChatWidget();
    },
    setChatbotMobileExample() {
      let obj = document.getElementById("chatbot-example");
      let mobileObj = document.getElementById("chatbot-mobile-example");
      mobileObj.innerHTML = obj.innerHTML;
    },
    colorHex(val) {
      return val === "blue"
        ? "#002f8e"
        : val === "green"
        ? "#008e46"
        : val === "red"
        ? "#8e0000"
        : val === "dark"
        ? "#34495e"
        : val === "purple"
        ? "#6e008e"
        : val === "yellow"
        ? "#c68700"
        : "blue";
    },
    resizeBase64Img(base64, callback) {
      let img = document.createElement("img");
      img.src = base64;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;
        const MAX_WIDTH = 168;
        const MAX_HEIGHT = Math.ceil((height / width) * MAX_WIDTH);

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        callback(canvas.toDataURL());
      };
    },
    validatePhone(e) {
      if (e.valid !== undefined) {
        this.validPhone = e.valid;
      }
    },
    blurWhatsapp() {
      this.addWhatsapp();
    },
    keyupWhatsapp() {
      this.addWhatsapp();
    },
  },
};
</script>

<style lang="scss">
.welcomeMessageInput .append-text {
  height: 37.59px;
}
.telephoneInput .vs-input--input.normal {
  padding: 0.2rem !important;
}
#vs4__combobox {
  height: 3rem !important;
}
@media only screen and (min-width: 590px) {
  .box-onboarding {
    width: 34rem !important;
  }
}
@media only screen and (min-width: 940px) {
  .box-onboarding {
    width: 56rem !important;
  }
}
.preview-btn-onboarding {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  position: fixed;
  top: 50%;
  z-index: 50000;
  left: -4.4rem;
}
.vs--open + .label-onboarding {
  color: rgba(var(--vs-primary), 1);
}
.chatbot-example-frame {
  max-width: 415px;
  min-width: 375px;
}
.chatbot-example {
  height: 600px;
  max-width: 415px;
  min-width: 375px;
}
#chatbot-example {
  height: 600px;
  max-width: 415px;
  min-width: 375px;
  background: #c1c1c1 !important;
  -webkit-box-shadow: 0 0.2px 2px rgba(0, 0, 0, 0.29),
    0 0.5px 4.7px rgba(0, 0, 0, 0.208), 0 0.9px 8.9px rgba(0, 0, 0, 0.173),
    0 1.6px 15.9px rgba(0, 0, 0, 0.145), 0 2.9px 29.7px rgba(0, 0, 0, 0.117),
    0 7px 71px rgba(0, 0, 0, 0.082);

  -moz-box-shadow: 0 0.2px 2px rgba(0, 0, 0, 0.29),
    0 0.5px 4.7px rgba(0, 0, 0, 0.208), 0 0.9px 8.9px rgba(0, 0, 0, 0.173),
    0 1.6px 15.9px rgba(0, 0, 0, 0.145), 0 2.9px 29.7px rgba(0, 0, 0, 0.117),
    0 7px 71px rgba(0, 0, 0, 0.082);

  box-shadow: 0 0.2px 2px rgba(0, 0, 0, 0.29),
    0 0.5px 4.7px rgba(0, 0, 0, 0.208), 0 0.9px 8.9px rgba(0, 0, 0, 0.173),
    0 1.6px 15.9px rgba(0, 0, 0, 0.145), 0 2.9px 29.7px rgba(0, 0, 0, 0.117),
    0 7px 71px rgba(0, 0, 0, 0.082);
}
.onboarding-loading {
  margin: 2rem 0 3rem 2rem;
}
.vue-tel-input {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.vue-tel-input:focus-within {
  border-color: rgba(var(--vs-primary), 1);
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.label-onboarding {
  font-size: 0.85rem;
  background-color: white;
  margin-left: 1rem;
  padding: 0 0.5rem 0 0.5rem;
  width: fit-content;
  position: relative;
  bottom: -10px;
  order: -1;
  z-index: 10;
}
.input-onboarding {
  padding-left: 8px;
}
.input-onboarding:focus {
  border: solid 1px rgba(var(--vs-primary), 1);
}
.input-onboarding:focus + span + .label-onboarding,
.input-onboarding:focus + .label-onboarding,
.vue-tel-input:focus-within + span + .label-onboarding {
  color: rgba(var(--vs-primary), 1);
}

/* v-swatches*/
.vue-swatches__swatch {
  margin-bottom: 11px;
}

.vue-swatches__container {
  @media (min-width: 900px) {
    display: flex;
  }
}

.vue-swatches__fallback__wrapper {
  @media (max-width: 900px) {
    margin-left: 0 !important;
    padding-bottom: 0 !important;
  }
}

.vue-swatches__fallback__input--wrapper {
  font-size: 0;
  padding-right: 0;
}

/* color-picker */
.color-picker {
  width: 42px;
  height: 42px;
  padding: 0;
  border-radius: 11px;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.color-picker:hover {
  opacity: 0.9;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.75);
  outline: none;
}

#color-picker::-webkit-color-swatch-wrapper {
  padding: 0;
}

#color-picker::-webkit-color-swatch {
  border-radius: 11px;
  border-style: none;
}

.color-picker-label {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 42px;
  height: 42px;
  display: grid;
  place-content: center;
  @media (min-width: 900px) {
    left: auto;
    bottom: auto;
    right: 0;
    top: 0;
  }
}

.color-picker-label span {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.15);
  display: grid;
  place-content: center;
}
</style>
