/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions

==========================================================================================*/

import axios from "@/axios.js"

export default {
  fetchChatbot({ commit }, chatbot_id) {
    return new Promise((resolve, reject) => {
      axios.get(`/p/chat/${chatbot_id}`)
        .then(response => {
          response = response.data.data

          if (response._id != null) {
            commit('SET_CURRENT_CHATBOT', response)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
